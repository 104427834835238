import styleNG from "./Next-genFiller.module.css"
import NGF from "../../../../img/R&D/Research Field/1-Next gen filler graphic_2.gif"
import {useInView} from "react-intersection-observer";

const NextGenFiller = () => {
    const [ref, inView] = useInView({
        triggerOnce: true,
    });
    const [ref1, inView1] = useInView({
        triggerOnce: true,
    });
    const [ref2, inView2] = useInView({
        triggerOnce: true,
    });
    return (
        <div className={styleNG.main}>
            <div ref={ref} className={`${styleNG.box} ${inView ? styleNG.animate0 : ''}`}>
                <div className={styleNG.titlebig}>
                    Next-Generation Filler?
                </div>
                <img className={styleNG.ngf} src={NGF} alt='NGF'/>
                <div className={styleNG.text}>
                    <b>Next-Generation Filler</b>는 현재 상용화 되어있는 히알루론산, PLLA, CaHA 기반의 필러의 단점과 한계를 극복한 차세대 필러입니다.
                </div>
            </div>
            <div ref={ref1} className={`${styleNG.box} ${inView1 ? styleNG.animate0 : ''}`}>
                <div className={styleNG.subtitle}>
                    Next-Generation Filler 소재가 가져야 할 특징은
                </div>
                <div className={styleNG.numberContainer}>
                    <div className={styleNG.infoBlock}>
                        <div className={styleNG.blueBlock}>1</div>
                        <div className={styleNG.textBlock}>
                            높은 생체 안전성과 <br/>
                            생분해성 제공
                        </div>
                    </div>
                    <div className={styleNG.infoBlock}>
                        <div className={styleNG.blueBlock}>2</div>
                        <div className={styleNG.textBlock}>
                            생체 독성을 띠는 화학적 가교제가 없는 겔 형성능
                        </div>
                    </div>
                    <div className={styleNG.infoBlock}>
                        <div className={styleNG.blueBlock}>3</div>
                        <div className={styleNG.textBlock}>
                            조직 형성 유도능 보유
                        </div>
                    </div>
                    <div className={styleNG.infoBlock}>
                        <div className={styleNG.blueBlock}>4</div>
                        <div className={styleNG.textBlock}>
                            효과 유지 기간을 늘려 <br/>
                            필러의 반복시술 저감
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${styleNG.line} ${inView2 ? styleNG.animate0 : ''}`}></div>
            <div ref={ref2} className={`${styleNG.box} ${inView2 ? styleNG.animate0 : ''}`}>
                <div className={styleNG.titlebig}>
                    코루파마의 차세대 필러
                </div>
                <div className={styleNG.subtitle}>
                    가교제 없는 차세대 필러 개발을 통해 시장 재편 기대
                </div>
                <div className={styleNG.containers}>
                    <div className={styleNG.firstContainer}>
                        <div ref={ref2} className={`${styleNG.blueContainer} ${inView2 ? styleNG.animate1 : ''}`}>
                            <p>자가조직 치환 필러</p>
                        </div>
                        <div className={styleNG.grayContainer}>
                            폴리포스파젠 필러
                        </div>
                        <ol className={styleNG.contentContainer}>
                            <li>비가교 하이드로젤</li>
                            <li>높은 생분해성과 높은 조직 형성능</li>
                            <li>낮은 주입력을 통한 부작용 완화</li>
                        </ol>
                    </div>
                    <div className={styleNG.secondContainer}>
                        <div ref={ref2} className={`${styleNG.lightblueContainer} ${inView2 ? styleNG.animate1 : ''}`}>
                            <p>BDDE가 없는 히알루론산 필러</p>
                        </div>
                        <div className={styleNG.grayContainers}>
                            <div className={styleNG.grayContainer}>
                                히알루론산/아가로즈 복합체 필러
                            </div>
                            <div className={styleNG.grayContainer}>
                                히알루론산 키토 가교 필러
                            </div>
                        </div>
                        <div className={styleNG.contentContainers}>
                            <ol className={styleNG.contentContainer}>
                                <li>천연 원료 필러</li>
                                <li>높은 생체 안전성과 생분해성</li>
                                <li>시술 후 제거 가능</li>
                            </ol>
                            <ol className={styleNG.contentContainer}>
                                <li>기존 HA필러와 유사한 물성</li>
                                <li>높은 생체 안전성, 생분해성</li>
                                <li>시술 후 제거 가능</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NextGenFiller;